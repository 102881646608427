body {
  background-image: url("./images/wood-g529fdf925_1920.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: white;
  background-size: cover;
  color: rgb(218, 218, 218);
  font-size: 20px;
}

header {
  background-image: url("./images/pexels-sohail-na-807598.jpg");
  height: 100px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.466);

  margin-bottom: 35px;
  text-align: center;
  padding: 17px;
}

#footer {
  font-size: 16px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.466);
  text-shadow: 2px 2px 5px black;
}

a:link, a:visited {
  text-decoration: none;
  color: #80cbc4;
}

a:hover {
  color: white;
}

button {
  padding: 10px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.466);
  border: 1px solid white;
}

/* Hero Row */
#heroRow {
  margin: 0px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
  text-shadow: 2px 2px 5px black;
  font-family: 'Ink Free', sans-serif;
}

#heroCol1 {
  transform: rotate(-10deg);
  padding: 20px;
}

#heroCol2 {
  padding: 20px;
  font-size: 30px;
}

#introSubtitle {
  font-size: 35px;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
}


/* Content Styling */


#aboutMeTitle, #workDesc, #contactMeDesc {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  font-family: 'Ink Free', sans-serif;
  color: rgb(211, 211, 211);
}

#aboutMe {
  display: none;
  margin-top: 50px;
}

#aboutMeText {
  background: rgba(0, 0, 0, 0.329);
  font-size: 16px;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.363);
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(211, 211, 211);
}

#aboutMePhoto {
  float: left;
  padding: 10px;
}

#workContainer {
  display: none;
  margin-top: 50px;
}

#workRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

#dailyPlanner, #tuneSpace, #weatherApp, #lifetracker, #techblog {
  transition-duration: 3s;
  padding: 0px;
  text-align: center;
  padding: 20px;
}

#dailyPlanner:hover,
#techblog:hover {
  padding: 0px;
  transform: rotate(-10deg);
  text-align: center;
  padding: 20px;
}

#weatherApp:hover {
  padding: 0px;
  text-align: center;
  transform: rotate(10deg);
  padding: 20px;
}

#tuneSpace:hover,
#lifetracker:hover {
  padding: 0px;
  transform: rotate(-10deg);
  text-align: center;
  padding: 20px;
}

#contactMe {
  margin-top: 50px;
  display: none;
}

#contactMeContent {
  margin: 50px;
  text-align: center;
  padding: 20px;
}

#contactMeContent img {
  padding: 30px;
}


/* Fixed bottom menu */

#fixedMenu {
  display: none;
}

.aboutMeLink, .workLink, .contactMeLink {
  font-size: 11px;
}

.introLink {
  margin-bottom: 30px;
}

#fixedMenu a {
  color: white;
}